import React, { useEffect, useState, useRef } from 'react';

const Gs1 = () => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const [captured, setCaptured] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        // Focus on the input field when the component mounts
        inputRef.current.focus();
    }, []);

    const handleKeyPress = (event) => {
        const code = event.code;
        const key = event.key;
        const keyCode = event.keyCode;
        const charCode = event.charCode;
        const altKey = event.altKey; // ALT key is pressed
        const ctrlKey = event.ctrlKey; // CTRL key is pressed

        setCaptured(captured.concat({ code, key, keyCode, charCode, altKey, ctrlKey }));
        console.log(captured);
    };

    const handleClearClick = () => {
        setInputValue('');
        setCaptured([]);
        setError(null);
        inputRef.current.focus();
    };

    return (
        <div className='container'>
            <p>Test how your barcode scanner interprets the Group Separator (GS) by scanning the barcode provided on this page.</p>
            <div className='mb-3'>
                <div className='row'>
                    <div class="col-md-6 text-center">
                        <img src="gs.gif"></img>
                    </div>
                    <div class="col-md-6 text-center">
                        <img src="hello.gif"></img>
                    </div>
                </div>
                <div className='row'>
                    <div class="col-md-6 text-center">
                        <span>&lt;Group Separator&gt;</span>
                    </div>
                    <div class="col-md-6 text-center">
                    <span>Hel&lt;Group Separator&gt;lo</span>
                    </div>
                </div>
            </div>
            <div>
                <input
                    className="form-control"
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    ref={inputRef}
                    autoFocus={true}
                    placeholder="Scan"
                />
                <button className="btn btn-outline-secondary mt-3" onClick={handleClearClick}>Clear</button>
            </div>

            {
                captured && (
                    <div className='mt-3'>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>code</th><th>key</th><th>keyCode</th>
                                    <th>charCode</th><th>altKey</th><th>ctrlKey</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    captured.map((obj, index) => (
                                        <tr key={index}>
                                            <td>{obj.code}</td>
                                            <td>{obj.key}</td>
                                            <td>{obj.keyCode}</td>
                                            <td>{obj.charCode}</td>
                                            <td>{obj.altKey ? 'Y' : ''}</td>
                                            <td>{obj.ctrlKey ? 'Y' : ''}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                )
            }

            {error && (<div>{error} ({inputValue})</div>)}
        </div>
    );
};

export default Gs1;
