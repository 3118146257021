import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faBoxOpen, faTruck } from '@fortawesome/free-solid-svg-icons';

import './Epcis.scss';
import { XMLParser } from 'fast-xml-parser';

const options = {
    removeNSPrefix: true,
    ignoreAttributes: false,
    parseTagValue: true,
    parseAttributeValue: true,
};
const parser = new XMLParser(options);

const Epcis = () => {
    const [docHeader, setDocHeader] = useState();
    const [vocabularies, setVocabularies] = useState([]);
    const [events, setEvents] = useState([]);
    const [isShowMoreLink, setShowMoreLink] = useState(false);
    const [isToggled, setToggled] = useState(false);
    const [showNoEvents, setShowNoEvents] = useState(false);

    useEffect(() => {
        const dropArea = document.getElementById('drop-area');
        const fileElem = document.getElementById('fileElem');

        dropArea.addEventListener('dragover', (e) => {
            e.preventDefault();
            dropArea.classList.add('highlight');
        });

        dropArea.addEventListener('dragleave', () => {
            dropArea.classList.remove('highlight');
        });

        dropArea.addEventListener('drop', (e) => {
            e.preventDefault();
            dropArea.classList.remove('highlight');
            const file = e.dataTransfer.files[0];
            handleFile(file);
        });

        fileElem.addEventListener('change', () => {
            const file = fileElem.files[0];
            handleFile(file);
        });

        function handleFile(file) {
            const reader = new FileReader();

            reader.onload = function (e) {
                setShowNoEvents(true);
                const content = e.target.result;
                const data = parser.parse(content);

                setDocHeader(data.EPCISDocument?.EPCISHeader?.StandardBusinessDocumentHeader);

                const objectEvents = data?.EPCISDocument?.EPCISBody?.EventList.ObjectEvent;
                const aggregationEvent = data?.EPCISDocument?.EPCISBody?.EventList.AggregationEvent;
                const options = { timeZone: "UTC", year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", timeZoneName: "short" };

                let tmpVocabularies = [];
                (data.EPCISDocument?.EPCISHeader?.extension?.EPCISMasterData?.VocabularyList?.Vocabulary || []).forEach((v) => {
                    let vType;
                    if (v['@_type'] === 'urn:epcglobal:epcis:vtype:Location') {
                        vType = 'Location';
                    } else if (v['@_type'] === 'urn:epcglobal:epcis:vtype:EPCClass') {
                        vType = 'EPCClass';
                    }

                    const vocabularyElements = [].concat(v.VocabularyElementList?.VocabularyElement || []);
                    vocabularyElements.forEach((i) => {
                        const obj = {
                            id: i['@_id'],
                            vType
                        };
                        const attributes = [].concat(i.attribute);
                        attributes.forEach(a => {
                            const s = a['@_id'].toString();
                            obj[s.substring(s.lastIndexOf('#') + 1)] = a['#text'];
                        });

                        tmpVocabularies.push(obj)
                    });
                });
                setVocabularies(tmpVocabularies);

                const tmpEvents = [].concat(objectEvents).concat(aggregationEvent).filter(event => !!event).map((event) => {
                    const eventTime = new Date(event.eventTime);

                    // Parse the time zone offset string
                    const timeComponents = event.eventTimeZoneOffset.split(":");
                    const timeZoneOffset = parseInt(timeComponents[0]) * 60 + parseInt(timeComponents[1]);

                    // Adjust the time based on the time zone offset
                    const eventTimeUtc = new Date(eventTime.getTime() + timeZoneOffset * 60000);

                    // Format the result as a string in UTC format
                    const eventTimeUtcString = new Intl.DateTimeFormat("en-US", options).format(eventTimeUtc);

                    const xEvent = {
                        time: eventTimeUtcString,
                        bizStep: getValueAfterLastColon(event.bizStep),
                        action: event.action,
                        disposition: getValueAfterLastColon(event.disposition),
                        parentID: event.parentID,
                        epcs: event.epcList ? [].concat(event.epcList.epc) : [],
                        childEPCs: event.childEPCs ? [].concat(event.childEPCs.epc) : [],
                        readPoint: event.readPoint?.id,
                        bizLocation: event.bizLocation?.id,
                        lotNumber: event.extension?.ilmd?.lotNumber,
                        itemExpirationDate: event.extension?.ilmd?.itemExpirationDate,
                        extension: event.extension
                    };
                    xEvent.showMore = xEvent.epcs.length > 10 || xEvent.childEPCs.length > 10;
                    if (xEvent.showMore) {
                        setShowMoreLink(true);
                    }
                    return xEvent;
                }).sort((a, b) => new Date(a.time) - new Date(b.time));
                setEvents(tmpEvents);
            };

            // Read the file as text
            reader.readAsText(file);
        }
    })

    const DocHeader = () => {
        if (docHeader) {
            const docId = docHeader.DocumentIdentification;
            return (
                <div>
                    <h4>EPCIS Document</h4>
                    <table className="table table-bordered table-striped">
                        <thead><tr><th>Sender</th><th>Receiver</th><th>Document</th><th>ID</th><th>Type</th><th>Created</th></tr></thead>
                        <tbody>
                            <tr>
                                <td>{docHeader.Sender.Identifier['#text']}</td>
                                <td>{docHeader.Receiver.Identifier['#text']}</td>
                                <td>{docId.Standard}({docId.TypeVersion})</td>
                                <td>{docId.InstanceIdentifier}</td>
                                <td>{docId.Type}</td>
                                <td>{new Intl.DateTimeFormat("en-US", options).format(new Date(docId.CreationDateAndTime))}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>)
        }
        return '';
    }

    const Vocabularies = () => {
        if (vocabularies && vocabularies.length) {
            return (<div>
                <h4>EPC Class</h4>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr><th>ID</th><th>NDC</th><th>Manufacturer</th><th>Product</th>
                            <th>Description</th><th>Doasage</th><th>Strength</th></tr>
                    </thead>
                    <tbody>
                        {
                            vocabularies.map((v, index) => {
                                if (v.vType === 'EPCClass' && v.additionalTradeItemIdentificationTypeCode === 'FDA_NDC_11') {
                                    return (
                                        <tr key={index}>
                                            <td>{getValueAfterLastColon(v.id)}</td>
                                            <td>{formatNdc(v.additionalTradeItemIdentification)}</td>
                                            <td>{v.manufacturerOfTradeItemPartyName}</td>
                                            <td>{v.regulatedProductName}</td>
                                            <td>{v.netContentDescription}</td>
                                            <td>{v.dosageFormType}</td>
                                            <td>{v.strengthDescription}</td>
                                        </tr>
                                    )
                                }
                                return '';
                            })
                        }
                    </tbody>
                </table>
            </div>)
        }
        return '';
    }

    const toggleContent = () => {
        setToggled(!isToggled);
    }

    const MoreLessLink = () => {
        return isShowMoreLink ? (<span className="more-link" onClick={toggleContent}>show {isToggled ? 'less' : 'more'}</span>) : ''
    }

    const BizIcon = ({ bizStep }) => {
        let icon = '';
        switch (bizStep) {
            case 'packing':
                icon = faBoxOpen;
                break;
            case 'shipping':
                icon = faTruck;
                break;
            default:
                icon = faFlask
        }
        return <FontAwesomeIcon icon={icon} />
    }

    const Events = () => {
        if (events) {
            if (events.length) {
                return (
                    <div>
                        <h4>Events</h4>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Timestamp</th><th>Action</th><th>BizStep</th><th>Disposition</th>
                                    <th>EPCs&nbsp;&nbsp;&nbsp;&nbsp;<MoreLessLink /></th>
                                    <th>Read Point / BizLocation</th><th>Extension</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    events.map((event, index) => (
                                        <tr key={index}>
                                            <td><BizIcon bizStep={event.bizStep} /> </td>
                                            <td className="text-nowrap">{event.time}</td>
                                            <td>{capitalizeFirstLetter(event.action)}</td>
                                            <td>                                            {capitalizeFirstLetter(event.bizStep)} </td>
                                            <td>{capitalizeFirstLetter(event.disposition)}</td>
                                            <td>
                                                {event.lotNumber && (<>Lot Number: {event.lotNumber}<br /></>)}
                                                {event.itemExpirationDate && (<>Expiration Date: {event.itemExpirationDate}<br /><br /></>)}
                                                {event.parentID && (<>{getValueAfterLastColon(event.parentID)}{event.childEPCs && (<> ({event.childEPCs.length})</>)}<br /></>)}
                                                {event.epcs && (<>
                                                    {event.epcs.map((epc, index2) => index2 <= 10 ? (<span key={index2}>{getValueAfterLastColon(epc)}<br /></span>) : isToggled && (<span key={index2}>{getValueAfterLastColon(epc)}<br /></span>))}
                                                </>)}
                                                {event.childEPCs && (<>
                                                    {event.childEPCs.map((epc, index2) => index2 <= 10 ? (<span key={index2}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{getValueAfterLastColon(epc)}<br /></span>) :
                                                        isToggled && (<span key={index2}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{getValueAfterLastColon(epc)}<br /></span>))}
                                                </>)}
                                                {event.showMore && !isToggled ? (<span>...</span>) : ''}
                                            </td>
                                            <td>
                                                {event.readPoint && (<Address vocabularies={vocabularies} id={event.readPoint} />)}
                                                {event.bizLocation && event.bizLocation != event.readPoint && (<Address vocabularies={vocabularies} id={event.bizLocation} />)}
                                            </td>
                                            <td><Extension extension={event.extension} vocabularies={vocabularies} />                                    </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                )
            } else {
                return showNoEvents && (<span>No Events</span>)
            }
        }
        return '';
    }

    return (
        <div className="container">
            <div id="drop-area">
                <h3 className="drop-text">Drag and Drop EPCIS XML File Here</h3>
                <form className="my-form">
                    <input type="file" id="fileElem" accept=".xml" style={{ display: 'none' }} />
                    <label className="button" htmlFor="fileElem">Select a file</label>
                </form>
            </div>
            <div id="result-container">
                <DocHeader />
                <Vocabularies />
                <Events />
            </div>
        </div>
    );
};

function getValueAfterLastColon(s) {
    return s && s.substring(s.lastIndexOf(':') + 1);
}

function capitalizeFirstLetter(str) {
    str = str.toLowerCase().replace('_', ' ');
    return str.replace(/\b\w/g, match => match.toUpperCase());
}

function formatNdc(s) {
    s = s.toString();
    return `${s.slice(0, 5)}-${s.slice(5, 9)}-${s.slice(9, 11)}`;
}

const Location = ({ objs, title, vocabularies }) => {
    if (objs) {
        const loc = objs.find(s => s['@_type'] === 'urn:epcglobal:cbv:sdt:location')
        return (<>{title}:<br /><Address vocabularies={vocabularies} id={loc['#text']} /></>);
    }
    return '';
}

const Address = ({ vocabularies, id }) => {
    const loc = vocabularies.find(v => v.id === id);
    return loc && (<>
        {loc.name}<br />
        {loc.streetAddressTwo && (<>{loc.streetAddressTwo}<br /></>)}
        {loc.streetAddressOne}<br />
        {loc.city} {loc.state}, {loc.postalCode}<br />
    </>);
}

const Extension = ({ extension, vocabularies }) => {
    return extension && (<>
        {extension.sourceList?.source && (<Location objs={extension.sourceList?.source} title='Source' vocabularies={vocabularies} />)}
        <br />
        {extension.destinationList?.destination && (<Location objs={extension.destinationList?.destination} title='Destination' vocabularies={vocabularies} />)}
    </>)
}

export default Epcis;
