import React, { useEffect, useState, useRef } from 'react';
import parseBarcode from '../lib/BarcodeParser'

const Gs1 = () => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const [parsed, setParsed] = useState();
    const [error, setError] = useState();


    useEffect(() => {
        // Focus on the input field when the component mounts
        inputRef.current.focus();
    }, []);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleEnterKeyPressed();
        }
    };

    const handleEnterKeyPressed = () => {
        console.log('Enter key pressed! Input Value:', inputValue);
        try {
            const tmp = parseBarcode(inputValue);
            setError(null);
            console.log(tmp);
            setParsed(tmp);
        } catch (err) {
            console.log(err)
            setError(err)
        }
    };

    const handleClearClick = () => {
        setInputValue('');
        setParsed(null);
        setError(null);
        inputRef.current.focus();
    };

    return (
        <div className='container'>
            <div>
                <input
                    className="form-control"
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    ref={inputRef}
                    autoFocus={true}
                    placeholder="Type/Scan and press Enter"
                />
                <button className="btn btn-outline-secondary mt-3" onClick={handleClearClick}>Clear</button>
            </div>

            {
                parsed && (
                    <div className='mt-3'>
                        <table className="table table-bordered table-striped">
                            <caption>{parsed.codeName}</caption>
                            <thead><tr><th>Code</th><th>Title</th><th>Data</th><th>Unit</th></tr></thead>
                            <tbody>
                                {parsed.parsedCodeItems.map((p, index) => (
                                    <tr key={index}>
                                        <td>{p.ai}</td>
                                        <td>{p.dataTitle}</td>
                                        <td>{p.data instanceof Date ? p.data.toString() : p.data}</td>
                                        <td>{p.unit}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            }

            {error && (<div>{error} ({inputValue})</div>)}
        </div>
    );
};

export default Gs1;
