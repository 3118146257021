import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Epcis from './components/Epcis';
import Gs1 from './components/Gs1';
import Scan from './components/Scan';

import './App.css';

function App() {
  return (
    <Router>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              {/*
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/epcis">EPCIS</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gs1">GS1</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li> */}
            </ul>
          </div>
        </nav>

        <hr />
        <Routes>
          <Route exact path="/" element={<Scan />} />
          <Route exact path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/epcis" element={<Epcis />} />
          <Route path="/gs1" element={<Gs1 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
